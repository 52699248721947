/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// ICONS
import { TiArrowSortedDown } from 'react-icons/ti';
import { RiInstagramLine } from 'react-icons/ri';
import { MdMailOutline } from 'react-icons/md';
import { HiMail } from 'react-icons/hi';
import { FiFacebook, FiMenu } from 'react-icons/fi';
import { FaFacebookF } from 'react-icons/fa';
import { BsTelephone } from 'react-icons/bs';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import Image from './Image';
import DrawerNavigation from './DrawerNavigation';
import SelectBlock from './SelectBlock';
import Chip from './Chip';
// SERVICES

const HeaderNavigation = (props) => {
  const {
    auth,
    // layout,
    phoneNumber,
    emailAddress,
    backgroundColor,
    menuItemsBlockBackgroundColor,
    menuItemColor,
    menuToggleIconColor,
    menuToggleIconSize,
    mobileMenuItemColor,
    submenuBlockBackgroundColor,
    submenuItemColor,
    fixedNavigationBarBackgroundColor,
    logo,
    icon,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    primarySocialMediaLink,
    secondarySocialMediaLink,
    hasSearchBlock,
    menuItems,
    hasChipFilter,
    hasRoundCorners,
    history
  } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileSubmenuVisible, setIsMobileSubmenuVisible] = useState(false);
  const [scroll, setScroll] = useState(1);

  const onScroll = () => {
    const scrollCheck = window.scrollY > 10;
    setScroll(scrollCheck);
  };

  useEffect(() => {
    onScroll();

    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll]);

  const popularSelections = [
    { id: 1, name: 'Filter 1', url: '' },
    { id: 2, name: 'Filter 2', url: '' },
    { id: 3, name: 'Filter 3', url: '' },
    { id: 4, name: 'Filter 4', url: '' },
    { id: 5, name: 'Filter 5', url: '' },
  ];

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.pageUrl && menuItem.pageUrl !== '') {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.pageUrl);
      } else {
        history.push(menuItem.pageUrl);
      }
    } else if (menuItem.customLink) {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.customLink);
      } else {
        history.push(menuItem.customLink);
      }
    }

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPage = (page) => {
    history.push(page);

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const toggleMobileSubmenu = () => {
    setIsMobileSubmenuVisible(!isMobileSubmenuVisible);
  };

  const sendEmail = (email) => {
    window.open(`mailto:${email}`, '_self');
  };

  const callPhoneNumber = (tel) => {
    window.open(`tel:${tel}`, '_self');
  };

  return (
    <>
      {/* MOBILE NAVIGATION */}
      <CustomBlock className="mobile-navigation">
        <CustomBlock className={`navigation-block ${backgroundColor}`}>
          {/* LOGO */}
          <CustomBlock className="logo-block">
            <Image
              source={`${process.env.REACT_APP_API_URL}Attachments/${icon}`}
              to="/"
            />
          </CustomBlock>

          {/* ICONS */}
          <CustomBlock className="icons-block">
            {/* SOCIAL ICONS */}
            <button
              onClick={() => openInNewTab(primarySocialMediaLink)}
              className="social-icon icon"
            >
              <FaFacebookF className={menuToggleIconColor} size={menuToggleIconSize - 10} />
            </button>

            <button
              onClick={() => {
                sendEmail(emailAddress);
              }}
              className="social-icon icon"
            >
              <HiMail className={menuToggleIconColor} size={menuToggleIconSize} />
            </button>

            {/* MENU TOGGLE */}
            <button
              className="menu-toggle icon"
              onClick={() => {
                toggleDrawer();
              }}
            >
              <FiMenu className="secondary--clr" size={menuToggleIconSize} />
            </button>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* HEADER NAVIGATION */}
      <CustomBlock className="header-navigation">
        {
          hasSearchBlock
          && (
            <CustomBlock className={`features-block ${backgroundColor}`}>
              {/* LOGO */}
              <CustomBlock className="logo-block">
                <Image
                  source={`${process.env.REACT_APP_API_URL}Attachments/${logo}`}
                  to="/"
                />
              </CustomBlock>

              {/* SEARCH */}
              {
                <CustomBlock className="search-block">
                  <SelectBlock
                    isAsync
                    placeholder="Search keywords, descriptions and titles..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();

                        history.push('/deals', {
                          predefinedFilter: {
                            sortBy: '',
                            priceRange: '',
                            categories: null,
                            searchTerm: e.target.value,
                            productTypes: '',
                            featuredSections: null,
                            skip: 0
                          }
                        });

                        e.target.blur();
                      }
                    }}
                    autoBlur
                    backgroundColor="white--sbg"
                    value={[]}
                    defaultOptions={[]}
                    // loadOptions={loadSuggestions}
                    noOptionsMessage={(e) => {
                      return (e.inputValue !== '') ? 'No Results Available' : 'Start typing and press enter to search';
                    }}
                    formatOptionLabel={(option) => (
                      <CustomBlock className="content-container--actions flex-start pl-0 mt-0 mb-10">
                        <CustomBlock className="smaller-image-container">
                          <Image
                            source={`${process.env.REACT_APP_API_URL}Attachments/${option.filePath}`}
                          />
                        </CustomBlock>
                        <p className="ml-15 fw-400">{option.label}</p>
                      </CustomBlock>
                    )}
                    onChange={(e) => {
                      history.push(`/deal/details/${e.value}`);
                    }}
                  />
                  {
                    hasChipFilter
                    && (
                      <CustomBlock className="chip-filter-block">
                        <CustomBlock className="chips-container">
                          {
                            popularSelections.map((item) => (
                              <Chip
                                hasBorder
                                label={item.name}
                                onClick={() => {

                                }}
                              />
                            ))
                          }
                        </CustomBlock>
                      </CustomBlock>
                    )
                  }
                </CustomBlock>
              }

              {/* ICONS BLOCK */}
              <CustomBlock className="icons-block">
                <button
                  onClick={() => openInNewTab(primarySocialMediaLink)}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <FiFacebook className={socialIconColor} size={iconSize} />
                </button>

                <button
                  onClick={() => openInNewTab(secondarySocialMediaLink)}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiInstagramLine className={socialIconColor} size={iconSize} />
                </button>

                <button
                  onClick={() => {
                    sendEmail(emailAddress);
                  }}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <MdMailOutline className={socialIconColor} size={iconSize} />
                </button>

                <Button
                  text={auth.authToken ? 'Dashboard' : 'Login'}
                  className="white--clr primary-lighter--sbg"
                  onClick={() => {
                    openInNewTab(`${auth.authToken ? '/cms/management/pages' : '/login'}`);
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          )
        }

        {/* MENU ITEMS */}
        {/* <CustomBlock className={`menu-items-block ${hasSearchBlock ? menuItemsBlockBackgroundColor : 'transparent-bg'} ${scroll ? (`${fixedNavigationBarBackgroundColor} fixed`) : ''}`}> */}
        <CustomBlock className={`menu-items-block ${hasSearchBlock ? menuItemsBlockBackgroundColor : 'transparent-bg'} ${scroll ? (`${fixedNavigationBarBackgroundColor} primary--sbg fixed`) : ''}`}>
          {
            !hasSearchBlock
            && (
              <CustomBlock className="logo-block">
                <Image
                  source={`${process.env.REACT_APP_API_URL}Attachments/${logo}`}
                  to="/"
                />
              </CustomBlock>
            )
          }

          <ul className={`menu-items ${!hasSearchBlock && 'has-width'}`}>
            <li>
              <Button
                text="Home"
                to="/home"
                className={`menu-item hover-animation--underline ${menuItemColor}`}
              />
            </li>
            {
              menuItems.map((menuItem) => (
                menuItem.assignedMenuItems.length > 0
                  ? (
                    <li className="has-submenu">
                      <Button
                        text={menuItem.name}
                        className={`menu-item hover-animation--underline ${menuItemColor}`}
                        onClick={() => {
                          handleMenuItemClick(menuItem);
                        }}
                      />
                      <CustomBlock className={`submenu-block default ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                        <ul className="submenu-items-block default">
                          {
                            menuItem.assignedMenuItems.map((submenuItem) => (
                              <li key={submenuItem.id}>
                                <Button
                                  text={submenuItem.name}
                                  onClick={() => {
                                    handleMenuItemClick(submenuItem);
                                  }}
                                  className={`menu-item ${submenuItemColor || menuItemColor}`}
                                />
                              </li>
                            ))
                          }
                        </ul>
                      </CustomBlock>
                    </li>
                  ) : (

                    <li>
                      <Button
                        text={menuItem.name}
                        onClick={() => {
                          handleMenuItemClick(menuItem);
                        }}
                        className={`menu-item hover-animation--underline ${menuItemColor}`}
                      />
                    </li>
                  )
              ))
            }
          </ul>

          {
            !hasSearchBlock
            && (
              <CustomBlock className="icons-block">
                <button
                  onClick={() => openInNewTab(primarySocialMediaLink)}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <FiFacebook className={socialIconColor} size={iconSize} />
                </button>

                {/* <button
                  onClick={() => {
                    openInNewTab(secondarySocialMediaLink);
                  }}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiInstagramLine className={socialIconColor} size={iconSize} />
                </button> */}

                <button
                  onClick={() => {
                    callPhoneNumber(phoneNumber);
                  }}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <BsTelephone className={socialIconColor} size={iconSize} />
                </button>

                <button
                  onClick={() => {
                    sendEmail(emailAddress);
                  }}
                  className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <MdMailOutline className={socialIconColor} size={iconSize} />
                </button>
              </CustomBlock>
            )
          }
        </CustomBlock>
      </CustomBlock>

      {/* DRAWER NAVIGATION */}
      <DrawerNavigation
        hasOverlay
        isOpen={isDrawerOpen}
        willCloseOnMaskPress
        onClick={() => {
          toggleDrawer();
        }}
      >
        <ul className="drawer-menu-items">
          <li>
            <Button
              text="Home"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/');
              }}
            />
          </li>
          {
            menuItems.map((menuItem) => (
              menuItem.assignedMenuItems.length > 0
                ? (
                  <li className="has-submenu">
                    <Button
                      text={menuItem.name}
                      className={`menu-item hover-animation--underline ${mobileMenuItemColor}`}
                      iconRight={<TiArrowSortedDown size={25} className="danger--clr ml-15" />}
                      onClick={() => {
                        toggleMobileSubmenu();
                      }}
                    />
                    <CustomBlock className={`submenu-block default ${isMobileSubmenuVisible ? 'visible' : 'hidden'} ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                      <ul className="submenu-items-block default">
                        {
                          menuItem.assignedMenuItems.map((submenuItem) => (
                            <li key={submenuItem.id}>
                              <Button
                                text={submenuItem.name}
                                className={`menu-item ${submenuItemColor || menuItemColor}`}
                                onClick={() => {
                                  handleMenuItemClick(submenuItem);
                                }}
                              />
                            </li>
                          ))
                        }
                      </ul>
                    </CustomBlock>
                  </li>
                ) : (

                  <li>
                    <Button
                      text={menuItem.name}
                      className={`menu-item hover-animation--underline ${mobileMenuItemColor}`}
                      onClick={() => {
                        handleMenuItemClick(menuItem);
                      }}
                    />
                  </li>
                )
            ))
          }
          <li>
            <Button
              text="Login"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/login');
              }}
            />
          </li>
        </ul>
      </DrawerNavigation>
    </>
  );
};

HeaderNavigation.propTypes = {
  // MAIN PROPS
  // layout: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  menuItemsBlockBackgroundColor: PropTypes.string,
  fixedNavigationBarBackgroundColor: PropTypes.string.isRequired,
  // to: PropTypes.string,

  // NAVIGATION PROPS
  menuItemColor: PropTypes.string,
  menuToggleIconColor: PropTypes.string,
  menuToggleIconSize: PropTypes.number,
  mobileMenuItemColor: PropTypes.string,

  // SUB MENU PROPS
  submenuBlockBackgroundColor: PropTypes.string,
  submenuItemColor: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  primarySocialMediaLink: PropTypes.string,
  secondarySocialMediaLink: PropTypes.string,

  // PRIMARY ICON PROPS
  // primaryActionIcon: PropTypes.any,
  // primaryActionIconText: PropTypes.string,

  // SECONDARY ICON PROPS
  // secondaryActionIcon: PropTypes.any,
  // secondaryActionIconText: PropTypes.string,

  // FUNCTION PROPS
  //   // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEANS PROPS
  hasSearchBlock: PropTypes.bool,

  // menuItems: PropTypes.array,
  hasChipFilter: PropTypes.bool,
  // hasFeaturedItems: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
};

HeaderNavigation.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',
  menuItemsBlockBackgroundColor: null,
  // to: '',

  // NAVIGATION PROPS
  menuItemColor: 'primary--clr',
  menuToggleIconColor: 'primary--clr',
  menuToggleIconSize: 35,
  mobileMenuItemColor: 'dark--clr',

  // SUB MENU PROPS
  submenuBlockBackgroundColor: 'white--sbg',
  submenuItemColor: 'primary--clr',

  // SOCIAL MEDIA PROPS
  iconSize: 22,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'primary-lighter--sbg',
  primarySocialMediaLink: '',
  secondarySocialMediaLink: '',

  // // BOOLEANS PROPS
  hasSearchBlock: false,
  hasChipFilter: false,
  // hasFeaturedItems: false,
  hasRoundCorners: false,
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(HeaderNavigation);