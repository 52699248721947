/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import { Route, useHistory } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// ICONS
import { BiPencil } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { CgCopy, CgWebsite } from 'react-icons/cg';
import { RiLayout5Fill, RiListCheck2 } from 'react-icons/ri';
import { HiChevronRight, HiMenuAlt4 } from 'react-icons/hi';
import {
  IoClose,
  IoList,
  IoLinkSharp
} from 'react-icons/io5';
import { BsPeopleFill } from 'react-icons/bs';
// CUSTOM COMPONENTS;
import MobileHeader from '../components/MobileHeader';
import HeaderBlock from '../components/HeaderBlock';
import Drawer from '../components/Drawer';
import SideSheet from '../components/SideSheet';
import ListBlock from '../components/ListBlock';
import CustomBlock from '../components/CustomBlock';
// ASSETS
// import Logo from '../assets/img/logo.svg';
// import Icon from '../assets/img/icon.svg';
// REDUX
import * as auth from '../redux/authRedux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { companyIconPath } = rest.cmsInfo;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [headerLogo, setHeaderLogo] = useState(Icon);
  const [navigationTitleVisible, setNavigationTitleVisible] = useState(false);
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const [sideSheetOpen, setSideSheetOpen] = useState(false);

  const history = useHistory();
  const body = document.querySelector('body');
  const iconSize = 27;
  const iconRightSize = 20;
  const menuIconColor = 'dark-grey--clr';
  const iconColor = 'primary--clr';

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    if (!isDrawerOpen) {
      setTimeout(() => {
        // setHeaderLogo(Logo);
      }, 500);

      setTimeout(() => {
        setNavigationTitleVisible(true);
      }, 300);
    } else {
      setSubMenuVisible(false);

      setTimeout(() => {
        setNavigationTitleVisible(false);
      }, 450);

      setTimeout(() => {
        // setHeaderLogo(Icon);
      }, 550);
    }
  };

  const navigateToPath = (path) => {
    history.push(path);
    setIsDrawerOpen(false);
    setSideSheetOpen(false);
    enableBodyScroll(body);
  };

  const toggleSideSheet = () => {
    setSideSheetOpen(!sideSheetOpen);
    if (!sideSheetOpen) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
  };

  const navigationItems = [
    {
      id: 1,
      iconLeft: <BsPeopleFill className={iconColor} size={iconSize} />,
      title: 'Users',
      to: '/admin/management/users',
      // badgeText: '',
      // badgeColor: '',
      isVisible: true,
      iconRightText: '',
      iconRightBackgroundColor: '',
      // buttonRightText: <></>,
    },
    {
      id: 2,
      iconLeft: <RiLayout5Fill className={iconColor} size={iconSize} />,
      title: 'Website Management',
      // to: '#',
      action: () => setSideSheetOpen(!sideSheetOpen),
      badgeText: '',
      badgeColor: '',
      isVisible: rest.auth.isAdmin,
      iconRightText: '',
      iconRightBackgroundColor: '',
      // buttonRightText: <></>,
    },
  ];

  const footerItems = [
    // {
    //   id: 0,
    //   iconLeft: <FiSettings className={iconColor} size={iconSize} />,
    //   title: 'Settings',
    //   iconRightText: '',
    //   iconRightBackgroundColor: '',
    //   action: () => setSideSheetOpen(!sideSheetOpen),
    // },
    {
      id: 1,
      iconLeft: <FiLogOut className={iconColor} size={iconSize} />,
      title: 'Logout',
      iconRightText: '',
      iconRightBackgroundColor: '',
      action: () => {
        rest.logout();
      },
    },
  ];

  const websiteManagementItems = [
    {
      id: 0,
      title: 'Menu Items',
      to: '/cms/management/menu-items',
      action: () => { },
      iconLeft: <IoList className={menuIconColor} size={iconSize} />,
    },
    {
      id: 1,
      title: 'Pages',
      to: '/cms/management/pages',
      action: () => { },
      iconLeft: <CgCopy className={menuIconColor} size={iconSize} />,
    },
    {
      id: 2,
      title: 'Blogs',
      to: '/cms/management/blogs',
      action: () => { },
      iconLeft: <BiPencil className={menuIconColor} size={iconSize} />,
    },
    {
      id: 3,
      title: 'Content Sections',
      to: '/cms/management/content-sections',
      action: () => { },
      iconLeft: <RiListCheck2 className={menuIconColor} size={iconSize} />,
    },
    {
      id: 4,
      title: 'Assets, Links and Resources',
      to: '/cms/management/assets-and-resources',
      action: () => { },
      iconLeft: <IoLinkSharp className={menuIconColor} size={iconSize} />,
    },
    {
      id: 5,
      title: 'Footer Items',
      to: '/cms/management/footer-items',
      action: () => { },
      iconLeft: <CgWebsite className={menuIconColor} size={iconSize} />,
    },
  ];

  return (
    <Route
      {...rest} render={(props) => (
        <>
          <MobileHeader
            hasBottomShadow
            // leftItemImageSource={Icon}
            leftItemImageSource={companyIconPath}
            leftItemImageTo="/cms/management/pages"
            rightItem={<HiMenuAlt4 className="white--clr" size={35} />}
            rightItemOnClick={() => toggleDrawer()}
          />
          <CustomBlock className="flex">
            <Drawer
              hasShadow
              isOpen={isDrawerOpen}
              // headerLogo={headerLogo}
              headerLogo={companyIconPath}
              mobileLogo={companyIconPath}
              // mobileLogo={Logo}
              backgroundColor="white--sbg"
              hasOverlay
              willCloseOnMaskPress
              closeActionOnClick={() => {
                toggleDrawer();
              }}
              navigationItems={
                navigationItems.filter((x) => x.isVisible).map((item, index) => (
                  <ListBlock
                    key={index}
                    title={navigationTitleVisible ? item.title : ''}
                    tooltipText={!navigationTitleVisible ? item.title : ''}
                    badgeColor={item.badgeColor}
                    onClick={item.to ? () => navigateToPath(item.to) : item.action}
                    isCollapsed={!navigationTitleVisible}
                    iconLeft={item.iconLeft}
                    iconRight={navigationTitleVisible && item.iconRight}
                    iconRightText={navigationTitleVisible ? item.iconRightText : ''}
                    iconLeftBadgeText={!navigationTitleVisible ? item.iconRightText : ''}
                    iconRightBackgroundColor={item.iconRightBackgroundColor}
                    hasRoundIconRight
                    rotateIconRight={subMenuVisible}
                    backgroundColor={rest.path === item.to ? 'secondary-lighter--sbg' : 'lighter-grey--sbg'}
                    subMenuItems={subMenuVisible && item.hasSubMenu}
                  />
                ))
              }

              footerItems={
                footerItems.map((item, index) => (
                  <ListBlock
                    key={index}
                    title={navigationTitleVisible ? item.title : ''}
                    tooltipText={!navigationTitleVisible ? item.title : ''}
                    badgeText={item.badgeText}
                    badgeColor={item.badgeColor}
                    onClick={item.to ? () => navigateToPath(item.to) : item.action}
                    isCollapsed={!navigationTitleVisible}
                    iconLeft={item.iconLeft}
                    iconRight={navigationTitleVisible && item.iconRight}
                    iconRightText={navigationTitleVisible ? item.iconRightText : ''}
                    iconLeftBadgeText={!navigationTitleVisible ? item.iconRightText : ''}
                    iconRightBackgroundColor={item.iconRightBackgroundColor}
                    hasRoundIconRight
                  />
                ))
              }
            />

            {/* MANAGEMENT PAGES SIDESHEET */}
            <SideSheet
              isOpen={sideSheetOpen}
              closeActionOnClick={() => toggleSideSheet()}
            >
              <CustomBlock className="side-sheet-menu">
                <CustomBlock className="close-action-container">
                  <button className="close-action" onClick={() => toggleSideSheet()}>
                    <IoClose className={menuIconColor} size={22} />
                  </button>
                </CustomBlock>

                {/* WEBSITE MANAGEMENT */}
                <CustomBlock className="header-items-container">
                  <p className="header-title">Website Management</p>
                </CustomBlock>
                <CustomBlock className="menu-items-container">
                  {
                    websiteManagementItems.map((item, index) => (
                      <ListBlock
                        key={index}
                        title={item.title}
                        titleColor="dark-grey--clr"
                        iconLeft={item.iconLeft}
                        backgroundColor={rest.path === item.to ? 'light-grey--sbg' : 'light--sbg'}
                        iconRight={<HiChevronRight className={menuIconColor} size={iconRightSize} />}
                        onClick={item.to ? () => navigateToPath(item.to) : item.action}
                      />
                    ))
                  }
                </CustomBlock>
              </CustomBlock>
            </SideSheet>

            <CustomBlock className="container-wrapper">
              <HeaderBlock
                hasBottomShadow
                // hasReturnAction
                leftItemText={rest.title}
                primaryButtonText="Go to Homepage"
                primaryButtonOnClick={() => {
                  window.open('/home', '_blank');
                }}
                rightItemPrimaryButtonColor="secondary--bg"
                rightItemPrimaryButtonOnClick={() => { }}
                rightItemSecondaryButtonText=""
                rightItemSecondaryButtonColor="primary--bg"
              />

              <CustomBlock className="main-container">
                <Component {...props} />
              </CustomBlock>

            </CustomBlock>
          </CustomBlock>
        </>
      )}
    />
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions })(PrivateRoute);